@mixin rotate($degrees) {
    $degressWithUnit: unit($degrees, deg);
    -ms-transform: rotate($degressWithUnit); /* IE 9 */
    -webkit-transform: rotate($degressWithUnit); /* Safari */
    transform: rotate($degressWithUnit);
}

@mixin user-select($user-select) {
  -webkit-touch-callout: $user-select;
  -webkit-user-select: $user-select;
  -khtml-user-select: $user-select;
  -moz-user-select: $user-select;
  -ms-user-select: $user-select;
  user-select: $user-select;
}
@mixin unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}
@mixin button {
    @include unselectable;
    cursor: pointer;
}
.generic-button {
    @include button;
    display: inline-block;
    background-color: $gray-08;
    color: #231f20;
    border-top: 1px solid $gray-10;
    border-right: 1px solid $gray-05;
    border-bottom: 1px solid $gray-05;
    border-left: 1px solid $gray-10;
    text-align: center;
    padding: 3px 5px;
    &:hover {
        background-color: $gray-10;
    }
    &:active {
        background-color: $gray-05;
        border-top: 1px solid $gray-05;
        border-right: 1px solid $gray-05;
        border-bottom: 1px solid $gray-05;
        border-left: 1px solid $gray-05;
    }
}
@mixin box_shadow {
  box-shadow: 2px 2px 5px $gray-03;
}
.placeholder-text {
    font-style: italic;
    color: $gray-05;
}
