$soft-borders: $gray-08;
$soft-fills: $gray-09;
$dark-green: #687f6f;
$table-th-background: $dark-green;
table {
  border: 1px solid $soft-borders;
  border-collapse: collapse;
  margin-bottom: 18px;
  font-size: 14px;
	thead {
		color: white;
		background-color: $table-th-background;
		font-weight: bold;
	}
	th {
		padding: 7px 12px;
		background-color: $table-th-background;
		border: 1px solid $soft-borders;
    text-align: left;
	}

	tbody {
		tr.odd td {
			background-color: $gray-09;
		}
		tr.even td {
			background-color: white;
		}
		td {
			position: relative;
			vertical-align: top;
			padding-top: 10px;
			padding: 7px 12px;
			border: 1px solid $soft-borders;
			ul, ol {
				margin: 0px;
				padding: 0px;
			}
		}
	}
}
