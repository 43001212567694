.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-info {
  @include sprite($-hurricane-u-i-elements-info3-default);

  &:hover {
    @include sprite($-hurricane-u-i-elements-info3-hover);
  }
}

.icon-arrowUp {
  @include sprite($scrollbar-up-arrow);
}

.icon-arrowDown {
  @include sprite($scrollbar-down-arrow);
}
