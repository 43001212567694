$splash-screen-gutter-width: 30px;
.splashScreen {
  position: absolute;
  z-index: 1000000;
  width: 960px - $splash-screen-gutter-width*2 - 2;
  height: 679px;
  background-color: white;
  padding: 10px $splash-screen-gutter-width;
  border: 1px solid $colorA-dark;

  .btn-primary {
    margin-right: 9px;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 14px;
  }

  div.header {
    border-bottom: 1px solid $gray-07;
    position: relative;
    height: 90px;

    .MFRLogo {
      position: absolute;
      display: inline-block;
      @include sprite($-m-f-r-logo-new-80x80);
    }
    .MFRLogoText {
      position: absolute;
      left: 96px;
      top: 18px;
      display: inline-block;
      @include sprite($-m-c-f-r-lettering-w-u-s);
    }
    h2 {
      position: absolute;
      left: 96px;
      top: 45px;
      font-style: italic;
      font-weight: normal;
    }
    div.header-buttons {
      position: absolute;
      right: -10px;
      bottom: 10px;
    }
  }
  .splash-content {
    margin: 0 0 0 28px;
  }
  .content-controlbar {
    position: relative;
    height: 64px;
    margin-top: 18px;
    position: relative;
    @include unselectable;

    ol {
      li {
        float: left;
        color: $flat-green1;
        font-weight: bold;
        font-size: 14px;
        padding-right: 10px;
        margin-right: 30px;
        padding-bottom: 0px;
        text-transform: uppercase;
        height: 25px;
        cursor: pointer;
        &:hover {
          border-bottom: 6px solid $flat-green1;
        }
        .number {
          display: inline-block;
          @include sprite($circle-btns);
          margin-right: 5px;
          position: relative;
          top: -8px;
          left: -2px;

          .value {
            position: relative;
            top: 8px;
            left: 10px;
            font-size: 16px;
            color: white;
          }
        }
        &.current {
          color: $colorA-dark;
          border-bottom: 6px solid $colorA-dark;
          cursor: default;
          .number {
            @include sprite($circle-btn-selected);
          }
        }
      }
    }

    .arrow-controls {
      position: absolute;
      right: -2px;
      top: -2px;
      span {
        cursor: pointer;
        display: inline-block;;
      }
    }
    .leftArrowIcon {
      margin-right: 4px;
      @include sprite($arrows-back-default);
      &:hover {
        @include sprite($arrows-back-hover);
      }
    }
    .leftArrowIcon.disabled {
      opacity: 0.25;
      cursor: default;
      &:hover {
        @include sprite($arrows-back-default);
      }
    }
    .rightArrowIcon {
      @include sprite($arrows-next-default);
      &:hover {
        @include sprite($arrows-next-hover);
      }
    }
    .rightArrowIcon.disabled {
      opacity: 0.25;
      cursor: default;
      &:hover {
        @include sprite($arrows-next-default);
      }
    }
  }
  .content {
    h2 {
      margin-bottom: 14px;
      font-weight: bold;
      color: $gray-01;
    }
    h3 {
      margin-bottom: 12px;
      font-size: 14px;
      color: $gray-01;
    }
    a.back-to-top {
      display: inline-block;
      margin-bottom: 24px;
    }
    a {
      color: $link-color;
      font-size: 14px;
      &.splash-nav {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    p {
      a:hover {
        text-decoration: underline;
      }
    }
    /* unvisited link */
    a:visited {
      color: $link-color;
    }
    /* visited link */

    .continueButton {
      color: $white;
      cursor: default;
      margin-left: 8px;
      &:hover {
        background-color: $button-green01;
        cursor: default;
      }
    }

    a.continueButton {
      width: 120px;
      margin-top: 6px;
      margin-right: 0px;
      text-align: center;
      font-family: 'Droid Sans', sans-serif;
      line-height: 14px;
    }

    p, ul, ol, span.help-italics {
      font-family: $font-text-block;
      color: $colorA-dark;
      width: 80%;
      font-size: 14px;
      line-height: 1.5em;
      margin-bottom: 1.4em;
      
      &.tagline {
        color: $gray-01;
        font-size: 21px;
        line-height: 1.2em;
        margin-bottom: 1.4em;
      }
      &.help-italics {
        font-style: italic;
      }
    }
    span.help-italics {
      font-style: italic;
    }
    span.help-icon {
      vertical-align: top;
      margin-left: 3px;
    }
    ul.quick-links {
      li {
        margin-bottom: 5px;
      }
      ul.content-list {
        margin-left: 12px;
        margin-top: 5px;        
        li {
          ul {
            margin-left: 18px;
            margin-bottom: 0px;
            li {
              list-style-type: circle;
              margin-top: 5px;
            }
          }
        }
      }
    }
    ul.content-list {
      list-style: disc;
      list-style-type: disc;
      padding-left: 20px;
      ul.content-list {
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
    ol.splash-list {
      margin-left: 19px;
      list-style-type: decimal;
      list-style-position: outside;
    }
    img.init-map-image {
      width: 325px;
    }
    img.continue-button-image {
      width: 95px;
    }
    img.decision-image {
      width: 425px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .probabilites-map-image, .probabilites-cone-image, .init-map-image, .record-events-image {
      border: 1px solid $gray-08;
    }
    .splash-details-box-image {
      width: 662px;
      height: auto;
    }
  }
  .content-container {
    overflow-y: auto;
    height: 430px;
    .content {
      ul li, ol li {
        margin-bottom: 5px;
      }
    }
  }
  .stormUpdate-detailsBox-image {
    width: 670px;
  }
  .splash-list {
    font-size: 16px;
    li span {
      font-weight: bold;
    }
  }

  .help-icon {
    cursor: default;
    @include sprite($-hurricane-u-i-elements-help3-default);
    &:hover {
      @include sprite($-hurricane-u-i-elements-help3-default);
    }
  }
}
