.dialog-view-announcement {
  z-index: 9075;
  .dialog-body {
    min-width: 458px;
    width: 458px;
    overflow: hidden;
  }
  div.ok-button {
    user-select: none;
    padding: 6px 28px;
    position: absolute;
    right: 15px;
    bottom: 12px;
  }
  .decision-buttons-container {
    display: inline-block;
    float: none;
  }
}
