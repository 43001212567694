$simulation-end-image-width: 450px;
$simulation-end-image-height: 370px;

.dialog-container, .dialog-container-overflow-hidden {
  width: 100%;
  height: 100.5%;
  position: absolute;
  top: 0px;
  left: 0px;
  &.splash-screen-hide {
    display: none;
  }
}
.dialog-container-overflow-hidden {
  overflow: hidden;
}
.dialog-overlay {
  z-index: 9050;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: $mask-color;
  opacity: 0.5;
  filter: alpha(opacity=50); /* For IE8 and earlier */
  @include unselectable;
}

.dialog {
  position: absolute;
  z-index: 10000;
  background-color: $gray-09;
  border: 5px solid $gray-02;
  border-top: 4px solid $gray-02;
  overflow: hidden;
  display: table;
  @include box_shadow;
}

.dialog-body {
  overflow: auto;
  max-height: 500px;
  max-width: 600px;
  min-width: 400px;
  min-height: 25px;
  background-color: white;

  h1, h2, h3, h4, h5 {
    color: $gray-02;
    padding-bottom: 4px;
  }
  p {
    padding-bottom: 12px;
    font-family: $font-text-block;
    line-height: 1.5em;
    font-size: 14px;
    margin-bottom: 0px;
  }
  padding: 16px;
}
div.dialog-header {
  width: 100%;
  height: 25px;
  background-color: $colorA-dark;
  text-align: center;
  @include unselectable;

  h2 {
    color: $gray-10;
    padding: 5px 16px;
    font-size: 16px;
    text-align: left;
  }
}
.close-icon {
    @include sprite($ic-close-white-18dp);
    display: inline-block;
    position: absolute;
    right: 5px;
    top: 2px;
    cursor: pointer;
}
span.grab-handle-icon {
  @include sprite($grab-handle-default);
  position: absolute;
  top: 1px;
  left: 215px;
  cursor: move;
  &:hover {
    @include sprite($grab-handle-hover);
  }
  display: none;
}
span.collapse-icon-left {
  @include sprite($collapse-icon-left-default);
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: -1px;
  &:hover {
    @include sprite($collapse-icon-left-hover);
  }
  display: none;
}
span.collapse-icon-right {
  @include sprite($collapse-icon-right-default);
  position: absolute;
  cursor: pointer;
  right: 0px;
  top: -1px;
  &:hover {
    @include sprite($collapse-icon-right-hover);
  }
  display: none;
}
span.minimize-icon {
  @include sprite($minimize-icon-default);
  position: absolute;
  cursor: pointer;
  right: 27px;
  top: -1px;
  &:hover {
    @include sprite($minimize-icon-hover);
  }
}
span.maximize-icon {
  @include sprite($maximize-icon-default);
  position: absolute;
  cursor: pointer;
  right: 27px;
  top: -1px;
  &:hover {
    @include sprite($maximize-icon-hover);
  }
}
