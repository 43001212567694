$configuration-top-height: 42px;
$configuration-top-padding-vert: 8px;
$configuration-left-width: 180px;

$sort-label-width: 80px;
$sort-select-width: 200px;

.configuration {
  position: relative;
}

.configuration-top {
  height: $configuration-top-height;
  width: percentage(($body-max-width - $configuration-left-width) / $body-max-width);
  vertical-align: middle;
  position: relative;
  margin-left: percentage(($configuration-left-width) / $body-max-width);
}

.sort {
  display: inline-block;
  float: left;
  font-size: 14px;
  padding-right: 10px;
  vertical-align: middle;
  height: $configuration-top-height - $configuration-top-padding-vert * 2;
  margin-top: $configuration-top-padding-vert;

  label {
    width: $sort-label-width;
    font-weight: bold;
    margin-right: 2px;
  }

  select {
    padding: 4px;
    box-sizing: border-box;
    height: $configuration-top-height - $configuration-top-padding-vert * 2;
    width: $sort-select-width;
  }
}

.count {
  font-size: 12px;
  display: inline-block;
  color: $gray-03;
  text-align: center;
  margin-top: $configuration-top-padding-vert + 4px;
  z-index: -1;
}

.configuration-left {
  position: absolute;
  left: $body-left-padding;
  top: 0px;
  width: percentage(($configuration-left-width - 2 * $body-left-padding) / $body-max-width);

  .title {
    margin: $configuration-top-padding-vert 0px;
    vertical-align: middle;
    font-size: 16px;
    line-height: 24px;
  }
}

.filter {
  margin-bottom: $configuration-top-padding-vert * 3;

  .bucket {
    cursor: pointer;
    user-select:  none;

    &.hover {
      background-color: $gray-09;
    }

    input,
    .label {
      vertical-align: middle;
    }

    input {
      margin-right: 4px;
    }

    > * {
      cursor: pointer;
    }
  }
}


@media (max-width: $breakpoint-phone) {

  /*.configuration-top {
    margin-left: 0px;
    width: 100%;

    .sort {
      margin-right: 10px;
    }

    .count {
      position: static;
      float: left;
      margin-top: 15px;
    }

    .pagination {
      position: static;
      float: right;
      height: 40px;


      .pages {
        float: none;
        padding: 10px 0px 10px 0px;
      }

    }

  }

  .configuration-left {
    position: static;
    width: 100%;
    padding: 60px 0px 10px 0px;

    .minDistanceToCity {
    }

    .maxWindSpeeds {
    }

    .maxProbabilityHurricaneWinds {
    }

    a {
    }
  }

  .filter {
    width: 33.3%;
  }
  */

}

@media (min-width: #{$breakpoint-phone}) and (max-width: #{$breakpoint-tablet}) {


}
