.pagination {

  font-size: 12px;
  position: absolute;
  top: $configuration-top-padding-vert + -2px;
  right: 0px;

  .current,
  .disabled {
    pointer-events: none;
    color: $black;
  }

  .pages {
    display: inline-block;
    user-select: none;
    float: right;
  }

  .pages-label {
    margin-right: 4px;
  }

  .page {
    display: inline-block;
    box-sizing: border-box;
    margin: 2px 0px;
    margin-right: 8px;
    text-align: center;

    &.current {
      border: solid 1px $gray-08;
      background: $gray-10;
      border-radius: 50%;
      padding: 2px 6px;
      min-width: 24px;
    }

    &.last {
      margin-right: 0px;
    }
  }

  .seperator {
    margin: 0px 4px;
  }

}
