.dialog-userFeedback {

  a {
    color: $link-color;
    cursor: pointer;
  }

  textarea.feedback {
    width: 80%;
    height: $base-font-size * 3;
    margin-bottom: $base-font-size * 2;
  }

  .submit {
    position: absolute;
    right: $base-font-size * 2;
    margin-top: $base-font-size * 0.25;
  }

  .copy-to-clipboard {
    float: right;
    display: inline-block;
  }

  .title {
    display: inline-block;
    margin-bottom: $base-font-size * 2;
  }

  .submitted {
    margin-bottom: $base-font-size * 2;
  }

  .event {
    margin-bottom: $base-font-size * 2;


    .title {
      margin-bottom: $base-font-size * 1;
    }

    .details {
      padding-left: $base-font-size * 1.5;
    }

    .item {
      margin-left: $base-font-size * 1.5;
      margin-bottom: $base-font-size * 0.5;
      list-style: disc;
    }
  }

  .stormInfo {
    margin-bottom: $base-font-size * 1;

    .title {
      margin-bottom: 0px;
    }
    .item {
      font-size: $base-font-size * 0.6;
      display: inline-block;
      width: 25%;
      margin: 0px;
    }
  }
}
