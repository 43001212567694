$btn-vertical-padding: 6px;
$btn-horizontal-padding: 15px;
$btn-margin-right: 4px;

.btn {
  user-select: none;
  padding: $btn-vertical-padding $btn-horizontal-padding;
  text-decoration: none;
  display: inline-block;
  background-color:  $gray-09;
  color: $colorA-dark;
  font-weight: bold;
  font-size: 12px;
	margin-top: 3px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: $link-color;
    background-color: $gray-10;
  }
  &.btn-disabled, &.btn-disabled:hover {
    color: $gray-07;
    background-color: $gray-09;
    cursor: default;
  }
}

.btn-primary {
  background-color: $button-green01;
  color: white;

  &:hover {
    background-color: $button-green02;
    color: white;
  }
}
.btn-danger {
  background-color: $button-red01;
  color: white;

  &:hover {
    background-color: $button-red02;
    color: white;
  }
}
.btn-big {
  font-size: 14px;
  padding: 4px 6px;
}
