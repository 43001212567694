.actions-icon {
  @include sprite($icon-btn-actions-default);
  display: inline-block;
  cursor: pointer;
  vertical-align:text-bottom;
  margin-right: 4px;
  &:hover {
    @include sprite($icon-btn-actions-hover);
  }
}
.details-icon {
  @include sprite($icon-btn-details-default);
  display: inline-block;
  cursor: pointer;
  vertical-align:text-bottom;
  &:hover {
    @include sprite($icon-btn-details-hover);
  }
}
.help-icon {
  display: inline-block;
  cursor: pointer;
  vertical-align: text-top;
  @include sprite($-hurricane-u-i-elements-help3-default);
  &:hover {
    @include sprite($-hurricane-u-i-elements-help3-hover);
  }
}
.helpIcon {
  display: inline-block;
  @include sprite($-hurricane-u-i-elements-help-default);
}

.map-north-text-icon {
  @include sprite($map-north-no-arrow);
}

.map-west-text-icon {
  @include sprite($map-west-no-arrow);
}

.play-icon {
  display: inline-block;
  vertical-align: text-top;
  margin-top: -1px;
  @include sprite($-hurricane-u-i-elements-p-l-a-y-default);
  &:hover, &.hover {
    @include sprite($-hurricane-u-i-elements-p-l-a-y-hover);
  }
}
.play-icon-hover {
  @include sprite($-hurricane-u-i-elements-p-l-a-y-hover);
}

.stop-icon {
  display: inline-block;
  vertical-align: text-top;
  margin-top: -1px;
  @include sprite($-hurricane-u-i-elements-s-t-o-p);
}
