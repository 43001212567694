$tooltip-connector-height: 20px;
$tooltip-connector-width: 16px;
$tooltip-connector-border-size: 1px;
$tooltip-connector-overlap-body: 0px;

$tooltip-connector-right: $event-margin - $tooltip-connector-border-size;
$tooltip-connector-top: -1 * ($tooltip-connector-height) + $event-margin-top;

$tooltip-row-decision-height: 14px * 8.0;

.progress-tooltip-connector, .progress-tooltip-body {
	background: $event-progress-event-current-bg;
	// opacity: 0.85;
}

.progress-tooltip-connector {
	position: absolute;
  margin-left: -$tooltip-connector-border-size;
	width: $tooltip-connector-width;
	height: $tooltip-connector-height;
	top: $tooltip-connector-top;
	right: $tooltip-connector-right;
	border-left: $tooltip-connector-border-size solid black;
	border-right: $tooltip-connector-border-size solid black;
}
