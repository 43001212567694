$topBar-right-side-box-oadding-right: 158px;
$topBar-height: 25px;
div.topBar {
	height: $topBar-height;
	background-color: $colorA-med-dark;

	div.topBarTitleBar {
	    width: 960px - $sideBar-width - $topBar-right-side-box-oadding-right;
			padding-left: $sideBar-width;
			padding-right: $topBar-right-side-box-oadding-right;
	    position: absolute;
	    height: 16px;
      top: 3px;
	    background-color: $colorA-dark;
	    border-top: 1px solid $gray-01;
	    border-bottom: 1px solid $gray-01;
	    text-align: center;

	    h1 {
	        margin: auto;
	        margin-top: 1px;
	    }
	}

	div.leftSideBox, div.rightSideBox {
	    a {
	        top: 2px;
	        position: absolute;
	        color: $gray-08;
	        font-weight: bold;
					z-index: 10000;
	    }
	}

	div {
	    span {
	        position: absolute;
	        margin-left: 3px;
	        top: -4px;
	    }
	}
	span.resourcesIcon {
		margin-top: 1px;
		@include sprite($-hurricane-u-i-elements-world-default);
	}
	a.resources {
    user-select: none;
	  margin-left: 16px;
		width: 60px;
		&:hover {
			color: $white;
			span.resourcesIcon {
				@include sprite($-hurricane-u-i-elements-world-hover);
			}
		}
	}
	span.helpIcon {
		margin-top: 1px;
	}
	.help {
    user-select: none;
	  margin-left: 87px;
		width: 55px;
		&:hover {
			color: $white;
			span.helpIcon {
				@include sprite($-hurricane-u-i-elements-help-hover);
			}
		}
	}
	span.feedbackIcon {
		margin-top: 1px;
		@include sprite($-hurricane-u-i-elements-chat-default);
	}
	.share-storm {
    user-select: none;
		margin-left: 150px;
		&:hover {
			color: $white;
			span.linkIcon {
				@include sprite($link-hover);
			}
		}
	}
	span.debugIcon {
		@include sprite($ic-settings-applications-grey600-18dp);
	}
	.debug-storm {
    user-select: none;
		margin-right: 150px;
		&:hover {
			color: $white;
			span.debugIcon {
				@include sprite($ic-settings-applications-white-18dp);
			}
		}
	}
	span.linkIcon {
		margin-top: 1px;
		@include sprite($link-default);
	}
	a.feedback {
	    margin-left: -14px;
	    z-index: 1;
		  width: 85px;
			&:hover {
				color: $white;
				span.feedbackIcon {
					@include sprite($-hurricane-u-i-elements-chat-hover);
				}
			}
	}

	div.leftSideBox {
    z-index: 500;
	  position: absolute;
	  height: 20px;
		width: 200px;
		margin-top: $topBar-height;
		background-color: $colorA-med-dark;
	}

	div.leftSideTriangleContainer {
	  position: absolute;
	  margin-top: $topBar-height;
		display: inline;
	  margin-left: 200px;
	  border-right: 1px solid $gray-07;

		div.leftSideTriangle {
	    background-color: $gray-09;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 20px 80px 0 0;
      border-color: $colorA-med-dark transparent transparent transparent;
		}
	}

	div.rightSideBox {
	    position: absolute;
	    height: 20px;
      width: 80px;
      margin-top: $topBar-height;
	    margin-left: 880px;
	    background-color: $colorA-med-dark;

			a {
				&:hover {
					color: $white;
				}
			}

	}

	div.rightSideTriangle {
        position: absolute;
        margin-top: $topBar-height;
        display: inline;
        margin-left: 800px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 80px 20px 0;
        border-color: transparent $colorA-med-dark transparent transparent;
    }
		&.helpCentered {
			position: relative;
			z-index: 100000;

			.topBarTitleBar {
					width: $simulation-width;
					padding-left: 0px;
					padding-right: 0px;
					h1 {
						color: $white
					}
			}
		}
		&.hideTitle {
			.topBarTitleBar {
					display: none;
			}
		}
}
