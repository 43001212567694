.reminder-blink {
  animation-name: reminder-blink-animation;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 12s;
  animation-delay: 5s;
}

@keyframes reminder-blink-animation {
  0% {
    opacity: 1.0;
  }

  1% {
    opacity: 0.0;
  }

  1.5% {
    opacity: 0.0;
  }

  2% {
    opacity: 1.0;
  }

  3% {
    opacity: 1.0;
  }

  3.5% {
    opacity: 0.0;
  }

  5% {
    opacity: 1.0;
  }
}

.copied-to-clipboard-blink {
  animation-name: reminder-blink-animation;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 6s;
}

@keyframes copied-to-clipboard-blink {
  0% {
    opacity: 0.0;
  }
  33% {
    opacity: 1.0;
  }

  66% {
    opacity: 0.0;
  }
  100% {
    opacity: 1.0;
  }
}

.slide-down {
  transition: 1s;
}

.slide-fast {
  transition: .2s;
}

.fade-out {
  opacity: 0;
  transition: opacity .3s;
}

.hidden {
   display: none;
}

.expand-in {
  transition: height 1s ease-in;
  height: inherit;
}
