$dialog-overlay-background-color: rgba($gray-02, 0.6);
$dialog-border-color: $title-background;
$dialog-border-size: 8px;
$dialog-margin: 24px;
$dialog-padding: 12px;

.dialog {
  background-color: $white;
  border: $dialog-border-size solid $dialog-border-color;
  border-top: none;
  overflow: auto;
  outline: none;
  box-sizing: border-box;
  text-align: left;
  font-size: $base-font-size;
  vertical-align: middle;
  display: inline-block;
  display: table;
}

.dialog-header {
  position: relative;
  background-color: $dialog-border-color;
  color: $white;
  margin: 0px;
  //  padding: $dialog-border-size / 2 $dialog-padding;

  .subtitle {
    font-size: 14px;
    margin-left: 10px;
    font-weight: normal;
    color: $white;
  }

  .title {
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: $dialog-border-size / 2;
    top: $dialog-border-size / 2;
    vertical-align: middle;
  }
}

.dialog-body {
  padding: $dialog-padding;
}

.dialog-overlay {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;

  background-color: $dialog-overlay-background-color;

  // https://css-tricks.com/centering-in-the-unknown/
  vertical-align: middle;
  text-align: center;
  font-size: 0px;
  white-space: nowrap;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.ReactModal__Overlay {
  opacity: 0.5;
  &--after-open {
    transition: opacity 200ms ease-out;
    opacity: 1;
  }
}

.ReactModal__Content {
  transform: scale(0);

  &--after-open {
    transform: scale(1);
    transition: all 200ms ease-in;
  }

  &--before-close {
    opacity: 0;
  }
}
