
.dialog-view-details, .dialog-view-actions, .dialog-help-details, .dialog-view-feedback {
  h1, h2, h3, h4, h5 {
    padding-bottom: 12px;
  }
  .actionText {
    white-space: pre-wrap;
    margin-bottom: 12px;
    font-family: $font-text-block;
    line-height: 1.5em;
    font-size: 14px;
  }
  span.decisionText {
    font-weight: bold;
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
  }
  p.probabilites-help {
    width: 660px;
  }
  div.dialog-body {
    line-height: 1.5;
    max-width: 675px;
  }
  .tide-table-top-row {
    background-color: $gray-09;
    td {
      text-align: center;
    }
  }
  .decisions-list, .announcements-list, .actions-list {
    font-family: $font-text-block;
    line-height: 1.5em;
    font-size: 14px;
    padding-left: 18px;
    padding-bottom: 12px;
    list-style: disc;
    list-type: disc;
    li {
      margin-bottom: 6px;
    }
  }
  img.probabilites-image {
    border: 1px solid $gray-08;
  }
  table tbody tr td {
    text-align: center;
  }
}

.dialog-simulation-over {
  width: 875px;
  .simulation-end-image, .simulation-end-image-plot-swath {
    position: absolute;
    width: $simulation-end-image-width;
    height: $simulation-end-image-height;
  }
  .simulation-end-image-plot-swath {
    -moz-opacity: 0.75;
    opacity: .75;
    filter: alpha(opacity=75);
  }
  h2 {
    font-size: 100%;
    font-weight: bold;
    color: $gray-01;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    padding-right: 6px;
    white-space: pre-line;
  }
  div.left-column {
    display: inline-block;
    width: 350px;
    margin: 0;
    float: left;
    overflow: auto;
    max-height: 500px;
  }
  div.right-column {
    display: inline-block;
    width: 350px;
    height: 356px;
    margin: 0 0 0 25px;
  }
  div.dialog-body {
    max-width: 875px;
    min-height: 420px;
    overflow: hidden;
  }
  .simulation-end-button-container {
    user-select: none;
    position: absolute;
    text-align: right;
    width: 100%;
    bottom: 12px;
    left: 0;
    @include unselectable;
  }

  .feedback-list {
    font-family: $font-text-block;
    line-height: 1.5em;
    font-size: 14px;
    padding-left: 18px;
    padding-bottom: 12px;
    list-style: disc;
    list-type: disc;
    li {
      margin-bottom: 6px;
    }
  }
}

.dialog-view-help, .dialog-view-about {
  div.dialog-body {
    line-height: 1.5;
    max-width: 676px;
    max-height: none;
    padding-bottom: 0;
  }
  p {
    width: 80%;
  }
  i {
    font-style: italic;
  }
  img.probabilites-image {
    width: 660px;
    height: 410px;
    border: 1px solid $gray-08;
  }
  ul.credits-list {
    margin-bottom: 0;
    padding-bottom: 12px;
    li {
      line-height: 1.35;
      font-family: $font-text-block;
      line-height: 1.5em;
      font-size: 14px;
      a {
        font-family: $font-text-block;
        line-height: 1.35em;
        font-size: 14px;
        margin-bottom: 0;
      }
    }
    &:last-of-type {
      li {
        line-height: 1.25em;
      }
    }
  }
  .credits-logos {
    background-color: #fff;
    padding-top: 12px;
    width: 650px;
    margin-left: 25px;
    padding-bottom: 16px;
    img {
      padding: 0;
    }
    .nps-logo {
      margin-right: 105px;
      float: left;
      height: 60px;
    }
    .ced3-logo {
      float: right;
      height: 60px;
      margin-right: 30px;
    }
    .marforres-logo {
      height: 60px;
      text-align: center;
      margin: auto;

    }
  }
  a.ced-text-link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.dialog-help-details {
  div.dialog-body {
    padding-bottom: 0;
  }
}

.dialog-view-feedback {
  div.feedback-input {
    width: 400px;
    height: 250px;
    display: block;
    margin-bottom: 9px;
    border: 1px solid $gray-07;
    padding: 6px;
  }
  .submitFeedback {
    float: right;
    margin-right: 0;
  }
  .dialog-body {
    min-height: 25px;
    overflow: hidden;
  }
}

.placeholder-actions {
  @extend .placeholder-text;
  font-size: 14px;
}

.dialog-share-storm {
  .dialog-body {
    min-height: 23px;
    min-width: 450px;
  }
}

.share-storm-input {
  // min-width: 375px;
  width: 95%;
  padding: 6px 8px;
}

.clipboard {
  margin-top: 12px;
  float: right;
}

.copied-to-clipboard {
  display: inline;
  font-weight: bold;
  font-size: 13px;
  position: absolute;
  top: 122px;
  left: 125px;
}
