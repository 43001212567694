$tooltip-body-decision-width: 464px;
$tooltip-body-decision-height: 112px;
$tooltip-body-decision-padding-top: 3px;
$tooltip-body-decision-padding-right: 4px;
$tooltip-body-decision-padding-bottom: 8px;
$tooltip-body-decision-padding-left: 4px;
$tooltip-body-decision-border-size: 1px;
$tooltip-body-decision-border: $tooltip-body-decision-border-size solid black;
$tooltip-body-decision-font-size-normal: 14px;

$tooltip-body-decision-right: $event-margin / 2;
$tooltip-body-decision-top: -1 * ($tooltip-body-decision-height + $tooltip-connector-height) + $tooltip-body-decision-border-size + $tooltip-connector-overlap-body + $event-margin-top;


.progress-tooltip-decision {
  .progress-tooltip-body {
  	position: absolute;
    width: 580px;
    height: 459px;
//  	width: $tooltip-body-decision-width - (2*$tooltip-body-decision-border-size);
//  	height: $tooltip-body-decision-height - (2*$tooltip-body-decision-border-size);
  	top: $tooltip-body-decision-top;
  	right: $tooltip-body-decision-right;
  	text-align: center;

  	border-radius: 5px;
  	border: $tooltip-body-decision-border;

  	list-style: none;

  	.row {

  		position: absolute;
  		width: $tooltip-body-decision-width - ($tooltip-body-decision-padding-left + $tooltip-body-decision-padding-right);
  		font-size: $tooltip-body-decision-font-size-normal;

  		margin-left: $tooltip-body-decision-padding-left;
  		margin-right: $tooltip-body-decision-padding-right;

  		.left {
  			display: inline-block;
  		    position: absolute;
  		    left: $tooltip-body-decision-padding-left;
  		}

  		.right {
  			display: inline-block;
  		    position: absolute;
  		    right: $tooltip-body-decision-padding-right;
  		}

  		&.top {
  			top: 0px;
  			padding-top: $tooltip-body-decision-padding-top;

  			.left {
  				top: $tooltip-body-decision-padding-top;
  			}

  			.right {
  				top: $tooltip-body-decision-padding-top;
  			}
  		}

  		&.middle {
  			top: $tooltip-body-decision-height / 2 - (2*$tooltip-body-decision-border-size + $tooltip-body-decision-font-size-normal/2);
  		}

  		&.bottom {
  			bottom: 0px;
  			padding-bottom: $tooltip-body-decision-padding-bottom;

  			position: absolute;
  			bottom: 0px;

  			.left {
  			    bottom: $tooltip-body-decision-padding-bottom;
  			}

  			.right {
  				bottom: $tooltip-body-decision-padding-bottom;
  			}
  		}
  	}

  	div.row.bottom {
  		border-top: 1px solid $gray-06;
  	}

  	span.decision.value, span.decision.label {
  		font-size: 10px;
  		color: $gray-04;
  	}

  	span.decision.value {
  		font-weight: bold;
  	}

  	h3.title {
  	    font-size: 14px;
  	}

  	span.time {
  		margin-top: 3px;
  	}

  	span.actionsIcon {
  		@include sprite($bell-notification-trans);
  		display: inline-block;
  		margin-top: 0px;
  		vertical-align: top;
  		text-align: center;
  		margin-right: 4px;
  		cursor: pointer;
  	}

  	a.details {
  		font-size: 12px;
  	  text-decoration: underline;
  	  color: blue;
  	}
  	span.no-details {
  		font-style: italic;
  		color: $gray-06;
  	}
  	span.label, span.value {
  		font-size: 12px;
  	}
  	span.value.big {
  		font-size: 16px;
  		font-weight: bold;
  	}

  	span.landfall.value.unit, span.probability.value.unit {
  		margin-top: 3px;
  		margin-left: -2px;
  		font-size: 12px;
  	}

  	p {
          padding-top: 8px;
          padding-bottom: 8px;
      }

      span.decisionText {
          font-weight: bold;
          text-overflow: ellipsis;
          width: 100%;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
      }

      span.windInfo {
  	    right: 0px;
  		float: right;
  		margin-top: 3px;
  	    color: $colorA-med-dark;
  	    font-weight: bold;

  	    span {
  	        margin-left: 12px;
  	    }
  	}
  }

  .progress-tooltip-body.recordEvent {
    @extend .sideBar-eventRecord-event;
  	@extend .sideBar-eventRecord-selectedEvent;
  	h3{@extend .sidebar-h3};
  	h2{@extend .sidebar-h2};
  	cursor: default;
  	border: $tooltip-body-decision-border;
  	text-align: left;
  }

  .row.decision {
		//top: $tooltip-body-decision-height - (2*$tooltip-body-decision-border-size + $tooltip-body-decision-font-size-normal/2);
		//height: $tooltip-row-decision-height;
		position: absolute;
    bottom: $tooltip-body-decision-padding-bottom;
	}
	.decision-description-container {
		padding-bottom: $tooltip-body-decision-font-size-normal;
	}
	.decision-recommendation-container {
		padding-bottom: $tooltip-body-decision-font-size-normal;
	}
	.decision-buttons-container {
		position: absolute;
		bottom: 0px;
	}
}
.decision-buttons-container {
  float: right;
}
.dialog-decision-container {
  z-index: 99;
  transition-duration: .5s;
}
.dialog-decision-no-transition-duration {
  transition-duration: none;
}

.dialog-non-modal {
  pointer-events: none;
}

.dialog-decision {
  pointer-events: auto;
  box-shadow: none;
  position: absolute;
  left: 10px;
  bottom: 3px;

  .dialog-body {
    width: 458px;
  }
  div.dialog-header {
    // h2 {
    //   padding-left: 30px;
    // }
  }

  p {
    color: $colorA-dark;
    width: 100%;
    font-size: 14px;
    margin-bottom: 0;
    line-height: 1.5em;

    &.decision-description {
      margin-bottom: 0px;
      font-weight: bold;
      color: #24262b;
    }
  }

  .decision-saved {
    font-size: 16px;
    font-weight: bold;
    width: 92%;
    text-align: center;
    display: block;
  }

  .collapse-icon-left {
    display: inline-block;
  }

  .grab-handle-icon {
    display: inline-block;
  }

  .minimize-icon {
    display: inline-block;
  }
}

.dialog-decision.minimized {
  top: 600px;
  left: 10px;

  .maximize-icon {
    display: inline-block;
  }
}

.slide-up-down {
  transition-duration: 0.5s;
}


.dialog-decision.docked-decision-event {
  $dialog-decision-docked-width: $sideBar-width - $tooltip-body-decision-padding-right - $tooltip-body-decision-padding-left - $sideBar-scrollbar-width;
//  width: $dialog-decision-docked-width;
position: absolute;
//top: 467px;
left: 10px;

  .dialog-body {
    min-width: $dialog-decision-docked-width - $event-padding-right - $event-padding-left;
    width: $dialog-decision-docked-width - $event-padding-right - $event-padding-left;
  }
  .collapse-icon-left {
    display: none;
  }
  .collapse-icon-right {
    display: inline-block;
  }
  span.grab-handle-icon {
    left: 107px;
  }
  .decision-recommendation-container {
    display: none;
  }
}
div.decision-button {
  user-select: none;
	padding: 6px 8px;
  margin-right: 0;
}
