$dialog-copy-url-width: $body-max-width / 2;
$dialog-copy-url-height: 164px;
$dialog-copy-url-blink-speed: 200ms;

.dialog-copy-url {
  width: $dialog-copy-url-width;
  height: $dialog-copy-url-height;

  .dialog-body {
    .title {
      display: inline-block;
      font-weight: bold;
      margin: 0px;
      margin-bottom: 14px;
      font-size: 14px;
      line-height: 14px;
    }

    .link {
      width: 100%;
      box-sizing: border-box;
      padding: 4px;
    }

    .buttons {
      position: absolute;
      right: 12px;
      bottom: 12px;

      > .btn {
        margin: 0px;
        margin-left: 14px;
        padding: 2px 16px;
      }
    }

    .info {
      position: absolute;
      left: 14px;
      bottom: 16px;
      vertical-align: middle;
      font-size: 14px;
      line-height: 14px;
    }

    .blink {
      animation: blink-animation 2 * $dialog-copy-url-blink-speed step-start $dialog-copy-url-blink-speed 2;
    }

    @keyframes blink-animation {
      0% {
        opacity: 1.0;
      }

      50% {
        opacity: 0.0;
      }
    }
  }
}
