$sideBar-width: 280px;
$sideBar-scrollbar-width: 18px;

$event-padding-top: 12px;
$event-padding-right: 16px;
$event-padding-bottom: 8px;
$event-padding-left: 16px;

span.decisionText, span.actionText, span.announcementText {
    text-overflow: ellipsis;
    width: 100%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 2px;
}

div.sideBar {
	width: $sideBar-width;
	margin-top: 20px;
	background-color: $gray-09;
	border-right: 1px solid $gray-07;
	display: inline-block;

	// custom scrollbar settings
	.mCSB_inside > .mCSB_container {
		margin-right: 0px;
	}
	.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background: $gray-03;
	}
	.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
		background: $gray-07;
	}
	h2, .sidebar-h2 {
	  display: inline-block;
		padding-top: 18px;
		padding-left: 16px;
		padding-bottom: 12px;
	}

	h3, .sidebar-h3 {
	  display: inline-block;
	  font-size: 14px;
	}
  .Records {
    transition: 1s;

    .scrollbar-thumb {
      background-color: $scrollbar-color;
      border-radius: 5px;
      &:hover {
        background-color: $scrollbar-color-hover;
        cursor: pointer;
      }
    }

    .scrollbar-thumb-hover {
      background-color: $scrollbar-color-hover;
      border-radius: 5px;
    }

    .scrollbar-track {
      background-color: $gray-07;
      position: absolute;
      width: 6px;
      right: 2px;
      bottom: 2px;
      top: 2px;
      border-radius: 3px;
      margin-top: 18px;
      margin-bottom: 18px;
    }

    .scrollbar-arrows {
      position: absolute;
      width: 11px;
      height: 10px;
      left: 267px;
      opacity: .3;
      transition: .2s;
      z-index: 1;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
    .icon-arrowUp {
      top: 101px;
    }
    .icon-arrowDown {
      bottom: 3px;
    }
  }

	.eventRecordScrollContainer {
		height: $simulation-height - 95px;
    margin-top: 4px;
    overflow-y: scrollbar;
    overflow-x: hidden;
	}

	ul.eventRecord {
    position: relative;

    li.event, .sideBar-eventRecord-event {
      user-select: none;
      cursor: pointer;
      border-bottom: 1px solid $gray-08;
			border-top: 1px solid $white;
      list-style: none;
      background-color: $gray-09;
      padding-top: $event-padding-top;
      padding-right: $event-padding-right;
      padding-bottom: $event-padding-bottom;
      padding-left: $event-padding-left;
      top: 0px;
      width: $sideBar-width - $event-padding-left - $event-padding-right - $sideBar-scrollbar-width;
			&:hover {
				background-color: $event-progress-event-current-bg;
			}
      p {
        padding-top: 8px;
        padding-bottom: 8px;
    	}

			span.decisionText, span.actionText, span.announcementText {
	        text-overflow: ellipsis;
	        width: 100%;
	        display: inline-block;
	        white-space: nowrap;
	        overflow: hidden;
					margin-bottom: 2px;
	    }

			span.decisionText {
	        font-weight: bold;
	    }

			span.announcementText {
				font-family: $font-text-block;
				color: $colorA-dark;
			}

	    span.windInfo {
		    right: 0px;
				float: right;
		    color: $colorA-med-dark;
				position: relative;
		    font-weight: bold;

				span.actions-icon {
					position: absolute;
					right: 110px;
					margin-right: 3px;
					text-align: center;
				}
				span.details-icon {
					position: absolute;
					right: 130px;
					margin-right: 3px;
					text-align: center;
				}
				span.hoursToLandfall {
					display: inline-block;
					padding-top: 3px;
					// width: 50px;
					text-align: right;
					margin-left: 0px;
				}
				span.probability {
					display: inline-block;
					width: 35px;
					text-align: right;
					margin-left: 8px;
				}
			}

			&.selectedEvent, .sideBar-eventRecord-selectedEvent {
				background-color: white;
			}
    }
	}

	span {
	    color: $gray-04;
	}
	.record-initial-storm-update {
		display: inline-block;
    margin-top: 3px;
		color: $link-color;
		font-weight: normal;
		&:hover {
			text-decoration: underline;
		}
	}
}
