$latitude-half-height: 11px;
$latitude-half-width: 6px;
$latitude-spacer: 75px;
$latitude-spacer-end-image: 58px;
$tick-size: 5px;

.contentViewPanel {
  .latitude-longitude-guide {
    pointer-events: none;
    position: absolute;
    width: $map-container-width;
    height: $map-container-height;
    top: 58px;
    left: 0px;
  }
  .latitude-guide {
    position: absolute;
    height: 100%;
    left: -13px;

    text-align: left;
    div.latitude {
      position: absolute;
      width: 29px;
      span.latitude-label {
        display: inline-block;
        font-size: 10px;
        color: $gray-06;
        margin-left: -4px;
      }
      span.latitude-tick {
        display: inline-block;
        vertical-align: middle;

        height: 1px;
        width: $tick-size;
        background-color: $gray-06;
        margin: 1px 0px 0px -2px;
      }
    }
  }
  .container-35 {
    margin-top: 0px;
  }
  .north-text {
    position: absolute;
    margin-top: $map-container-height / 2 - $map-north-no-arrow-height / 2 - 1px;
    margin-left: -5px;
  }
  .container-30 {
    position: absolute;
    margin-top: $map-container-height / 5 * 1 - $latitude-half-height;
  }
  .container-25 {
    position: absolute;
    margin-top: $map-container-height / 5 * 2 - $latitude-half-height;
  }
  .container-20 {
    position: absolute;
    margin-top: $map-container-height / 5 * 3 - $latitude-half-height;
  }
  .container-15 {
    position: absolute;
    margin-top: $map-container-height / 5 * 4 - $latitude-half-height;
  }
  .container-10 {
    position: absolute;
    margin-top: $map-container-height / 5 * 5 - $latitude-half-height;
  }
  .longitude-guide {
    position: absolute;
    top: $map-container-height + 4;
    width: 100%;
    left: -24px;

    text-align: right;
    div.longitude {
      position: absolute;
      span.longitude-tick {
        display: block;
        height: $tick-size;
        width: 1px;
        background-color: $gray-06;
        margin: 0px auto;
        margin-left: 5px;
        position: absolute;
      }
      span.longitude-label {
        display: block;
        font-size: 10px;
        color: $gray-06;
        position:absolute;
        margin-top: 7px;
        margin-left: 0px;
      }
    }
  }
  .container-95 {
    left: $latitude-spacer;
  }
  .west-text {
    position: absolute;
    left: $map-container-width / 2 - $map-west-no-arrow-width / 2 + 14px;
    margin-top: $map-west-no-arrow-height;
  }
  .container-90 {
    position: absolute;
    left: $latitude-spacer * 2;
  }
  .container-85 {
    position: absolute;
    left: $latitude-spacer * 3;
  }
  .container-80 {
    position: absolute;
    left: $latitude-spacer * 4;
  }
  .container-75 {
    position: absolute;
    left: $latitude-spacer * 5;
  }
  .container-70 {
    position: absolute;
    left: $latitude-spacer * 6;
  }
  .container-65 {
    position: absolute;
    left: $latitude-spacer * 7;
  }
  .latitude-longitude-guide.hide-map {
    display: none;
  }
}


div.dialog-simulation-over, div.dialog-simulation-begin {
  .latitude-longitude-guide {
    position: absolute;
    width: $simulation-end-image-width;
    height: $simulation-end-image-height;
    background-color: rgba(0,0,0,0.10);
  }
  .coordinates-label {
    text-align: center;
    width:  100%;
    display: block;
    margin-top: 21px;
    margin-left: 21px;
    color: $gray-06;
  }
  .latitude-guide {
    position: absolute;
    height: 100%;
    left: -13px;

    text-align: left;
    div.latitude {
      position: absolute;
      width: 29px;
      span.latitude-label {
        display: inline-block;
        font-size: 10px;
        color: $gray-06;
        margin-left: -4px;
      }
      span.latitude-tick {
        display: inline-block;
        vertical-align: middle;

        height: 1px;
        width: $tick-size;
        background-color: $gray-06;
        margin: 1px 0px 0px -2px;
      }
    }
  }
  .container-35 {
    margin-top: 0px;
  }
  .north-text {
    position: absolute;
    margin-top: $simulation-end-image-height / 2 - $map-north-no-arrow-height / 2 - 2px;
    margin-left: -5px;
  }
  .container-30 {
    position: absolute;
    margin-top: $simulation-end-image-height / 5 * 1 - $latitude-half-height;
  }
  .container-25 {
    position: absolute;
    margin-top: $simulation-end-image-height / 5 * 2 - $latitude-half-height;
  }
  .container-20 {
    position: absolute;
    margin-top: $simulation-end-image-height / 5 * 3 - $latitude-half-height;
  }
  .container-15 {
    position: absolute;
    margin-top: $simulation-end-image-height / 5 * 4 - $latitude-half-height;
  }
  .container-10 {
    position: absolute;
    margin-top: $simulation-end-image-height / 5 * 5 - $latitude-half-height;
  }
  .longitude-guide {
    position: absolute;
    top: $simulation-end-image-height + 1;
    width: 100%;
    left: -20px;

    text-align: right;
    div.longitude {
      position: absolute;
      span.longitude-tick {
        display: block;
        height: $tick-size;
        width: 1px;
        background-color: $gray-06;
        margin: 0px auto;
        margin-left: 5px;
        position: absolute;
      }
      span.longitude-label {
        display: block;
        font-size: 10px;
        color: $gray-06;
        position:absolute;
        margin-top: 7px;
        margin-left: 0px;
      }
    }
  }
  .container-95 {
    left: $latitude-spacer-end-image;
  }
  .west-text {
    position: absolute;
    left: $simulation-end-image-width / 2 - $map-west-no-arrow-width / 2 + 11px;
    margin-top: $map-west-no-arrow-height;
  }
  .container-90 {
    position: absolute;
    left: $latitude-spacer-end-image * 2;
  }
  .container-85 {
    position: absolute;
    left: $latitude-spacer-end-image * 3;
  }
  .container-80 {
    position: absolute;
    left: $latitude-spacer-end-image * 4;
  }
  .container-75 {
    position: absolute;
    left: $latitude-spacer-end-image * 5;
  }
  .container-70 {
    position: absolute;
    left: $latitude-spacer-end-image * 6;
  }
  .container-65 {
    position: absolute;
    left: $latitude-spacer-end-image * 7;
  }
  .latitude-longitude-guide.hide-map {
    display: none;
  }
}
