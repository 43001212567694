.__react_component_tooltip.type-dark.custom-tool-tip {
  z-index: 100000 ;
  padding: 4px 10px 4px 10px;
  border-color: $title-background;
  font-size: 14px *.95;
  opacity: 1;
  color: $white;
  font-weight: normal;
  text-align: center;
  line-height: 17px;
  border-radius: 4px;
  background-color: $title-background;


  &.place-top {
    &::after {
      border-top-color: $title-background;
    }
  }

  &.place-right {
    &::after {
      border-right-color: $title-background;
    }
  }

  &.place-bottom {
    &::after {
      border-bottom-color: $title-background;
    }
  }

  &.place-left {
    &::after {
      border-left-color: $title-background;
    }
  }
}

// .__react_component_tooltip.type-dark.custom-tool-tip.place-top::after {
//   border-top-color: $black;
// }
