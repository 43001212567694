
$tooltip-body-storm-width: 496px;
$tooltip-body-storm-height: 90px;
$tooltip-body-storm-padding-top: 3px;
$tooltip-body-storm-padding-right: 4px;
$tooltip-body-storm-padding-bottom: 2px;
$tooltip-body-storm-padding-left: 4px;
$tooltip-body-storm-border-size: 1px;
$tooltip-body-storm-border: $tooltip-body-storm-border-size solid black;
$tooltip-body-storm-font-size-normal: 14px;

$tooltip-body-storm-top: -1 * ($tooltip-body-storm-height + $tooltip-connector-height) + $tooltip-body-storm-border-size + $tooltip-connector-overlap-body + $event-margin-top;
$tooltip-body-storm-right: $event-margin / 2;

.progress-tooltip-storm {
  .progress-tooltip-body {
  	position: absolute;
  	width: $tooltip-body-storm-width + (2*$tooltip-body-storm-border-size);
  	height: $tooltip-body-storm-height - (2*$tooltip-body-storm-border-size);
  	top: $tooltip-body-storm-top;
  	right: 0; //$tooltip-body-storm-right;

  	text-align: center;

  	border-radius: 5px;
  	border: $tooltip-body-storm-border;

  	list-style: none;

    .copy-of-event-record {
      font-size: 14px;
      padding-top: 13px;
      padding-bottom: 8px;
      padding-left: 15px;
      padding-right: 17px;
      color: $color-a-dark;
      text-align: left;

      h3 {
        width: 90px;
      }

      .details-icon, .actions-icon {
        padding-right: 1px;
      }

      .windInfo {
        margin-top: 0px;
      }

      .decisionText {
        margin-bottom: 3px;
      }

      .probability {
        font-size: 12px;
      }

      .record-initial-storm-update {
        color: $link-color;
      }
    }

  	.row {

  		position: absolute;
  		width: $tooltip-body-storm-width - ($tooltip-body-storm-padding-left + $tooltip-body-storm-padding-right);
  		font-size: $tooltip-body-storm-font-size-normal;

  		margin-left: $tooltip-body-storm-padding-left;
  		margin-right: $tooltip-body-storm-padding-right;

  		.left {
  			display: inline-block;
  		    position: absolute;
  		    left: $tooltip-body-storm-padding-left;
  		}

  		.right {
  			display: inline-block;
  		    position: absolute;
  		    right: $tooltip-body-storm-padding-right;
  		}

  		&.top {
  			top: 1px;
  			padding-top: $tooltip-body-storm-padding-top;

  			.left {
  				top: $tooltip-body-storm-padding-top;
  			}

  			.right {
  				top: $tooltip-body-storm-padding-top - 1;
  			}
        h3 {
          text-transform: uppercase;
        }
  		}

  		&.middle {
  			top: $tooltip-body-storm-height / 2.25 - (2*$tooltip-body-storm-border-size + $tooltip-body-storm-font-size-normal/2);
    		span.probability.value.big {
          margin-left: 0px;
        }
      }

  		&.bottom {
  			bottom: 0px;
  			padding-bottom: $tooltip-body-storm-padding-bottom;

  			position: absolute;
  			bottom: 0px;

  			.left {
  			    bottom: $tooltip-body-storm-padding-bottom;
  			}

  			.right {
  				bottom: $tooltip-body-storm-padding-bottom;
  			}
  		}
  	}

  	div.row.bottom {
  		border-top: 1px solid $gray-06;
      padding-top: 3px;
      padding-bottom: 3px;
      div.left {
        width: 166px;
        top: 0px;
        display: inline-block;
        position: relative;
        left: -11.5px;
      }
      div.middle.first {
        position: relative;
        display: inline-block;
        top: 0px;
        width: 100px;
        left: -2px;
      }
      div.middle.second {
        position: relative;
        display: inline-block;
        top: 0px;
        width: 115px;
        left: 7px;
      }
      div.right {
        position: relative;
        display: inline-block;
        top: 0px;
        right: -13.5px;
        width: 95px;
      }
  	}
    span.landfall.label {
      display: block;
      position: relative;
    }
  	span.storm.value, span.storm.label {
      display: block;
  		font-size: 12px;
  		color: $gray-04;
  	}
    span.probability.value, span.landfall.value, span.storm.value {
      font-size: 13px;
      color: $gray-04;
      font-weight: bold;
      margin-top: 3px;
    }
    span.landfall.label, span.storm.label {
      color: $gray-05;
    }
    span.probability.unit, span.landfall.unit, span.storm.unit {
      font-size: 12px;
      font-weight: normal;
      display: inline-block;
      margin-left: -2px;
    }
  	span.storm.value {
  		font-weight: bold;
      display: inline-block;
  	}

  	h3.title {
  	    font-size: 14px;
  	}
  	span.time {
  		margin-top: 3px;
      color: $gray-05;
  	}

  	span.actionsIcon {
  		vertical-align: top;
  		text-align: center;
  	}
    a.actions {
      color: $link-color;
      &:hover {
        text-decoration: underline;
      }
    }
    span.actions-icon {
      @include sprite($icon-btn-actions-default);
    }
    a.details {
      color: $link-color;
      &:hover {
        text-decoration: underline;
      }
    }
    span.details-icon {
      &:hover {
        @include sprite($icon-btn-details-default);
      }
    }
    a.actions, span.no-actions {
      margin-right: 15.5px;
    }
  	span.no-actions {
  		font-style: italic;
  		color: $gray-06;
  	}
  	span.label, span.value {
  		font-size: 12px;
  	}
  	span.value.big {
  		font-size: 16px;
  		font-weight: bold;
  	}

  	 span.probability {
      position: relative;
  		margin-left: -2px;
  		font-size: 15px;
      &.placeholder-text {
        top: 1px;
      }
  	}

  	p {
          padding-top: 8px;
          padding-bottom: 8px;
      }

      span.decisionText {
          font-weight: bold;
          text-overflow: ellipsis;
          width: 100%;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
      }

      span.windInfo {
  	    right: 0px;
    		float: right;
    		margin-top: 3px;
  	    color: $colorA-med-dark;
  	    font-weight: bold;

  	    // span {
  	    //     margin-left: 12px;
  	    // }

        span.actions-icon {
					position: absolute;
					right: 126px;
					margin-right: 3px;
					text-align: center;
				}
				span.details-icon {
					position: absolute;
					right: 146px;
					margin-right: 3px;
					text-align: center;
				}
				span.hoursToLandfall {
					display: inline-block;
					padding-top: 3px;
					// width: 50px;
					text-align: right;
					margin-left: 0px;
				}
				span.probability {
					display: inline-block;
					width: 35px;
					text-align: right;
					margin-left: 8px;
				}
  	}
  }

  .progress-tooltip-body.recordEvent {
  	@extend .sideBar-eventRecord-event;
  	@extend .sideBar-eventRecord-selectedEvent;
  	h3{@extend .sidebar-h3};
  	h2{@extend .sidebar-h2};
  	cursor: default;
  	border: $tooltip-body-storm-border;
  	text-align: left;
  }
}
.progress-tooltip-storm.floating {
  top: -394px;
  left: -35px;
  span.probability {
    margin: 0;
  }
  div.vertical-container {
    display: block;
    min-height: 8px;
    margin: 6px auto;
    &:first-child {
      margin: 0px auto 4px auto;
    }
    &:last-child {
      margin: 0px auto;
    }
  }
  .progress-tooltip-body {
    width: 580px;
    height: 459px;
    border: 0px;
    left: 35px;
    background-color: transparent;
    pointer-events: none;
  }
  .progress-tooltip-connector {
    display: none;
  }
  .progress-tooltip-city-actions {
    position: absolute;
    top: 4px;
    left: 6px;
    pointer-events: all;
    background-color: white;
    width: 175px;
    border: 1px solid $gray-05;
    padding: 6px 8px 6px 8px;
    @include box_shadow;
    span.value-unit-container, span.value-unit-container-last {
      display: block;
    }
    span.value-unit-container {
      margin-bottom: 4px;
    }
    span.landfall.label, span.probability.label {
      display: block;
      font-size: 12px;
      color: $gray-03;
      margin: 0 auto;
    }
    span.landfall.value, span.landfall.unit, span.probability.value, span.probability.unit {
      font-size: 14px;
    }
    span.landfall.value.unit.hrs {
      margin-left: 2px;
    }
    a.actions {
      margin-right: 0px;
    }
    span.no-actions {
      margin: 0px auto;
    }
    .container-left {
      text-align: left;
    }
    .container-right {
      position: absolute;
      right: 10px;
      top: 3px;
    }
  }
}
