//Color A
$colorA-dark: #373B47;
$colorA-med-dark: #434A5A;

//Grays
$gray-01: #24262B;
$gray-02: $colorA-dark;
$gray-03: $colorA-med-dark;
$gray-04: #52565E;
$gray-05: #777B7F;
$gray-06: #989A9C;
$gray-07: #BBBBBB;
$gray-08: #D7D6D5;
$gray-09: #EAE9E8;
$gray-10: #EFEEED;
$white: #fff;

//"Flat Color" Accents
$flat-green1: #6F8E6C;
$flat-green2: #91B68D;

//Player
$bright-red: #F90101;
$bright-green: #00FF00;

//Probablities Legend
$key-90: #CA00CE;
$key-80: #980000;
$key-70: #FE0000;
$key-60: #FE9834;
$key-50: #B37F19;
$key-40: #CBCC19;
$key-30: #DBFE01;
$key-20: #98FE30;
$key-10: #02CA00;
$key-05: #326600;

$red: #CC0000;
$error-dark: #990000;

$link-color: #0044cc;

//Button
$button-bg: #00A551;
$button-green01: #00A551;
$button-green02: #0A7C3B;

$button-red01: #F90601;
$button-red02: #d80505;

// Event Progress Bar
$event-progress-bg: #434A5A;
$event-progress-event-bg: #9FA4A4;
$event-progress-event-current-bg: $white;

$mask-color: #3b3e44;
