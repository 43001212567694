$hurricane-grid-margin-left: $configuration-left-width;

$hurricane-grid-hurricane-margin-right: 10px;
$hurricane-grid-hurricane-padding-size: 4px;
$hurricane-grid-info-padding-size: 0px 0px 0px 5px;
$hurricane-grid-hurricane-border-size: 3px;
$hurricane-grid-hurricane-columns-desktop: 4;
$hurricane-grid-hurricane-columns-tablet: 3;
$hurricane-grid-hurricane-columns-phone: 2;

$hurricane-grid-hurricane-map-aspect-ratio: 130 / 165;

$hurricane-grid-info-columns: 3;

.page {
  .session-id {
    font-size: 26px;
    width: 100%;
    text-align: center;
    color: $red;
    margin-top: 10px;
  }
}

.dialog-body {
  .session-id {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

@mixin hurricane-grid($hurricane-grid-hurricane-columns) {

  .hurricane-grid {
    position: relative;
    margin-left: percentage(($hurricane-grid-margin-left) / $body-max-width);

    .loading-indicator {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 10000;
    }

    .hurricane {
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      $hurricane-grid-hurricane-total-width: $body-max-width - (($hurricane-grid-hurricane-columns - 1) * $hurricane-grid-hurricane-margin-right);
      $hurricane-grid-hurricane-width-px: $hurricane-grid-hurricane-total-width / $hurricane-grid-hurricane-columns;
      width: percentage($hurricane-grid-hurricane-width-px / $body-max-width);
      margin-right: percentage($hurricane-grid-hurricane-margin-right / $body-max-width);
      margin-bottom: 16px;

      border: $hurricane-grid-hurricane-border-size solid $gray-09;
      padding: $hurricane-grid-hurricane-padding-size $hurricane-grid-hurricane-padding-size;

      &:nth-child(#{$hurricane-grid-hurricane-columns}n+1) {
        margin-right: 0px;
      }

      &:nth-child(#{$hurricane-grid-hurricane-columns}n-#{$hurricane-grid-hurricane-columns - 2}) {
        margin-left: 0px;
      }

      .info-box {
        font-size: 14px;
        padding: $hurricane-grid-info-padding-size 0px;
        .storm-id {
          font-size: 11px;
          color: $gray-03;
        }
      }

      .get-url {
        float: right;
        font-size: 12px;
        width: 33%;
        text-align: right;

        & > a {
          outline: 0;
        }
      }

      .name {
        font-weight: bold;
      }

      .filter-info {
        display: inline-block;
        color: $gray-02;
        font-size: 14px;
        line-height: 14px;
        // margin-right: 8px;
        padding-top: 5px;
        width: 33.33%;
        text-align: center;
        white-space: nowrap;

        &:nth-child(#{$hurricane-grid-info-columns}n+1) {
          text-align: left;
        }

        &:nth-child(#{$hurricane-grid-info-columns}n+#{$hurricane-grid-info-columns}) {
          text-align: right;
        }

        & > span {
          display: inline-block;
        }
      }

      .storm-info {
        line-height: 12px;
      }

      .hurricane-map-container {
        padding-bottom: 3px;
        position: relative;
        overflow: hidden;
        cursor: pointer;

        // https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
        &::before {
          display: block;
          content: " ";
          width: 100%;
          padding-top: $hurricane-grid-hurricane-map-aspect-ratio * 100%;
        }

        > .content {
          position: absolute;
          left: 0px;
          top: 0px;
          right: 0px;
          bottom: 0px;
        }

        &:hover {
          opacity: .5;
        }


      }

      .hurricane-map-image {
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
      }
    }
  }
}

// phone
@media (max-width: $breakpoint-phone) {
  @include hurricane-grid($hurricane-grid-hurricane-columns-phone);

  .hurricane-grid {
    .hurricane {
      .filter-info {
        font-size: 16px;
      }

      .storm-info {
        padding: 4px 0px;
      }

      .info-box {
        .get-url {
          font-size: 14px;
          padding: 0px;
          width: auto;
          text-align: left;
        }

        .storm-id {
          float: left;
          font-size: 14px;
        }
      }
    }
  }

}

// tablet
@media (min-width: #{$breakpoint-phone}) and (max-width: #{$breakpoint-tablet}) {
  @include hurricane-grid($hurricane-grid-hurricane-columns-tablet);

  .hurricane-grid {
    .hurricane {
      .hurricane-map-container {
        padding-bottom: 0px;
      }

      .info-box {
        .get-url {
          font-size: 12px;
        }

        .storm-id {
          font-size: 12px;
        }
      }
    }
  }

}

// desktop
@media (min-width: $breakpoint-tablet) {
  @include hurricane-grid($hurricane-grid-hurricane-columns-desktop);
}
