$dialog-view-hurricane-width: $body-max-width - 80px;
$dialog-view-hurricane-height: 680px;
$dialog-view-hurricane-margin: 16px;

.dialog-view-hurricane {
  width: percentage($dialog-view-hurricane-width / $body-max-width);
  max-width: $body-max-width - 2 * $dialog-view-hurricane-margin;
  border: none;

  .dialog-header {
    position: absolute;
    top: 4px;
    left: 0px;
    right: 0px;
    z-index: 1;
    background: none;
    color: $black;

    .close {
      right: 12px;

      background-image: url($close-image);
      width: 30px;
      height: 30px;

      &:hover {
        opacity: .5;
      }
    }
  }

  .dialog-body {
    position: relative;

    .hurricane-map-container {
      width: 100%;
      overflow: hidden;
      position: relative;

      // https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
      &:before {
        display: block;
        content: " ";
        width: 100%;
        padding-top: $hurricane-grid-hurricane-map-aspect-ratio * 100%;
      }

      > .content {
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        bottom: 0px;
      }
    }

    .hurricane-map-image {
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
    }
  }
}
