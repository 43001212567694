$simulation-height: 635px;
$simulation-width: 960px;

// @import "libs/jquery.mCustomScrollbar.scss";
@import "shared/colors.scss";
@import "shared/fonts.scss";
@import "shared/buttons.scss";
@import "shared/tables.scss";
@import "shared/mixins.scss";
@import "shared/animations.scss";
@import "simulation/icons.scss";
@import "shared/dialog.scss";
@import "simulation/sideBar.scss";
@import "simulation/topBar.scss";
@import "simulation/contentPanel.scss";
@import "simulation/dialog/decision.scss";
@import "simulation/dialog/viewAnnouncement.scss";
@import "simulation/dialog/viewDetails.scss";
@import "simulation/dialog/stormBegin.scss";
@import "simulation/ie8hacks.scss";
@import "simulation/splashScreen.scss";

body {
	width: $simulation-width;
	height: $simulation-height;
	margin: auto;
	font-family: $font-default;
	position: relative;
}
.body-container {
  height: $simulation-height;
	overflow: hidden;
	position: relative;
}

// Fixes blurry images in Chrome
img {
  image-rendering:-webkit-optimize-contrast;
}

h1, h2, h3 {
	font-weight: bold;
}

h1 {
	color: $gray-07;
}

h2 {
	color: $colorA-dark;
}
h3 {
	color: $flat-green1;
}
ul {
	padding-left: 0px;
}

p, span {
	color: $colorA-dark;
	font-size: 12px;
}

a {
	text-decoration: none;
	cursor: pointer;
}

.tooltip {
  white-space: normal;
}
.instructions {
	font-style: italic;
}

.footer {
  height: 16px;
  background-color: $colorA-med-dark;
  z-index: 99999;
}
