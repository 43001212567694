$map-container-width: 580px;
$map-container-height: 459px;
$progress-width: 500px;
$progress-height: 24px;
$progress-margin-top: 8px;

$event-margin: 8px;
$event-margin-top: 2px;

$labels-padding-top: 0px;
$labels-padding-right: 0px;
$labels-padding-bottom: 4px;
$labels-padding-left: 0px;

$probabilitySpeeds-row-padding-top: 4px;
$probabilitySpeeds-button-padding-vertical: 5px;
$probabilitySpeeds-button-padding-horizontal: 28px;

$contentViewPanel-margin-top: 10px;
$contentViewPanel-margin-left: 20px;

@import "content/index";

div.contentViewPanel {
	display: inline-block;
	position: absolute;
	margin-top: $contentViewPanel-margin-top;
	margin-left: $contentViewPanel-margin-left;

	h2 {
	    display: inline;
	}

	p.thresholdText {
	    margin-top: 4px;
	}

    span.dialogIcon {
        position: absolute;
        margin-top: -2px;
    }

  .eventContainer {
		margin-top: 140px;

		.simulationEndBox {
			display: none;
			position: absolute;
			left: $progress-width + 6px;
			top: 520px;
			width: ($map-container-width - $progress-width - 4px) * 2;
			height: 75px;
			border: none;
			border-top: solid 5px $colorA-med-dark;
			background: $gray-09;
		}

		a.playButton, a.stopButton, a.showMap, a.hideMap {
  		position: absolute;
			width: 130px;
			left: $progress-width + 12;
			margin-top: 6px;
			margin-right: 0px;
			text-align: center;
		}
		a.showMap, a.hideMap {
			padding: 4px 6px;
			font-size: 14px;
			top: 525px;
		}
		a.playButton, a.stopButton {
			top: 557px;
			margin-top: 4px;
			padding: 2px 6px;
			.play-icon, .stop-icon {
				margin-left: 4px;
			}
		}
		a.hideMap, a.stopButton {
			display: none;
		}
		a.viewResultsButton {
			position: absolute;
			top: 529px;
			width: 130px;
			left: $progress-width + 12;
			padding: 4px 6px;
			text-align: center;
		}
		a.newSimulationButton {
			position: absolute;
			top: 557px;
			left: $progress-width + 12;
			width: 130px;
			padding: 4px 6px;
			text-align: center;
		}
		.progress {
			width: $progress-width;
			height: $progress-height;
			display: inline-block;
			position: relative;
			//margin-top: $progress-margin-top;

			.progressBar {
				height: $progress-height;
				background: $event-progress-bg;
			}
			.event {
				user-select: none;
				cursor: pointer;
				background-color: $event-progress-event-bg;
				display: inline-block;
				margin-top: $event-margin-top;
				position: absolute;

				&.currentEvent {
					.details {
						display: block;
					}
				}

				&.selectedEvent {
					background-color: $event-progress-event-current-bg;
					user-select: none;
				}
				&:hover {
					background-color: $gray-08;
				}
			}

			.timeline-label {
				color: $gray-05;
				padding-bottom: 5px;
				font-size: 12px;
			}

			.labels {
				margin-top: $progress-margin-top / 2;
				color: $gray-01;

				.row {

					position: absolute;
					width: $progress-width - ($labels-padding-left + $labels-padding-right);

					margin-left: $labels-padding-left;
					margin-right: $labels-padding-right;

					padding-top: $labels-padding-top;
					padding-bottom: $labels-padding-bottom;

					text-align: center;

					.left, .right, .middle {
						top: 0px;
						color: $gray-05;
					}

					.left, .right {
						display: inline-block;
					  position: absolute;
						font-style: italic;
						font-size: 12px;
						margin-top: 0px;
					}

					.middle {
						color: $gray-04;
						font-weight: bold;
						font-size: 14px;
					}

					.left {
					    left: $labels-padding-left;
					}

					.right {
						right: $labels-padding-right;
					}
				}
			}
		}
  }


	div.probabilityKeyContainer {
	    margin-top: 24px;
			margin-left: 616px;
	    position: relative;

	    h2 {
	        margin-left: -10px;
	    }

	    span {
			position: absolute;
			color: $flat-green1;
			left: 13px;
			font-size: 12px;
		}

	    span.label {
	    	bottom: -6px;
	    }

	    div.key {
					width: 10px;
	        height: 30px;
	        background-color: black;
					position: relative;
					span.label {
						bottom: -7px;
						//left: 12px;
					}
					span.label.top {
						top: -5px;
					}
	    }

	    div.container90 {
	        background-color: $key-90;
					margin-top: 10px;
	    }

		div.container80 {
		    background-color: $key-80;
		}

		div.container70 {
		    background-color: $key-70;
		}

		div.container60 {
		    background-color: $key-60;
		}

		div.container50 {
		    background-color: $key-50;
		}

		div.container40 {
		    background-color: $key-40;
		}

		div.container30 {
		    background-color: $key-30;
		}

		div.container20 {
		    background-color: $key-20;
		}

		div.container10 {
		    background-color: $key-10;
		}

		div.container05 {
		    background-color: $key-05;
		}
	}
}

.progress-tooltip {
	position: relative;
}


.probabilitySpeeds {

	h2.title.wide {
		letter-spacing: 1.5px;
	}

	span.title.description {
		margin-left: 4px;
		margin-right: 4px;
		font-weight: bold;
	}

	span.time {
		font-size: 14px;
		font-weight: bold;
	}

	.wind-speed-button-39mph,
	.wind-speed-button-58mph,
	.wind-speed-button-74mph,
	.wind-speed-button-cone {
    user-select: none;
	  padding: $probabilitySpeeds-button-padding-vertical $probabilitySpeeds-button-padding-horizontal;
    text-decoration: none;
    display: inline-block;
		text-align: center;
		width: 50px;
    background-color:  $gray-09;
    color:$colorA-dark;
    font-weight: bold;
    font-size: 14px;
    margin-right: 13px;
		margin-top: 5px;
		&:hover {
			background-color: $gray-10;
			color: $link-color;
		}
		&:active {
			background-color:  $gray-09;
	    color:$colorA-dark;
		}
		&.current {
			background-color: $event-progress-bg;
	    color: $white;
		}
	}

	.row {
		position: relative;
		width: 100%;
		margin-top: 5px;

		&.top {
			padding-top: $probabilitySpeeds-row-padding-top;

			.left {
				position: absolute;
				left: 0px;
			}

			.middle {
				margin-top: $probabilitySpeeds-button-padding-vertical;
				font-weight: bold;
			}
		}
	}

	.row.time {
		//text-align: center;
	}
}

div.mapContainer {
	position: absolute;
	top: -1 * $contentViewPanel-margin-top + 70px;
	//top: -1 * $contentViewPanel-margin-top;
	// left: $contentViewPanel-margin-left;
	z-index: 0;
	width: $map-container-width;
	height: $map-container-height;
	// overflow: hidden;
	// border: 1px solid $gray-08;
	> img {
		width: $map-container-width;
		position: absolute;
		top: 0px;
		left: 0px;
		//clip: rect(0px,680px,448px,0px);
	}

	> span {
		position: absolute;
		top: 0px;
		left: 0px;
	}

	img.hurricane {
		opacity: 0.78;
		filter: alpha(opacity=78); /* For IE8 and earlier */
	}

  div.markerContainer {
    position: absolute;
    top: 55px;
    left: 10px;
    width: 561px;
    height: 360px;

    span.city {
      position: absolute;
    	@include sprite($ic-stars-black-24dp);
  		margin-top: -1 * $ic-stars-black-24dp-height / 2;
  		margin-left: -1 * $ic-stars-black-24dp-width / 2;
  		z-index: 1000;
  		cursor: pointer;
  	}

  }
}
