$simulation-begin-image-width: 450px;
$simulation-begin-image-height: 356px;

.dialog-simulation-begin {
  width: 860px;
  img {
    position: absolute;
    width: $simulation-begin-image-width;
  }
  h2 {
    font-size: 100%;
    font-weight: bold;
    color: $gray-01;
  }
  p {
    font-size: 14px;
    line-height: 1.5;
    padding-right: 6px;
    white-space: pre-line;
  }
  div.left-column {
    display: inline-block;
    width: 350px;
    margin: 0;
    float: left;
    overflow: auto;
    max-height: 500px;
  }
  div.right-column {
    display: inline-block;
    width: 350px;
    height: 356px;
    margin: 0 0 0 25px;
  }
  div.dialog-body {
    max-width: 875px;
    min-height: 415px;
    overflow: hidden;
  }
  .simulation_begin_button_container {
    user-select: none;
    position: absolute;
    text-align: right;
    width: 100%;
    bottom: 12px;
    left : 0px;
    @include unselectable;
    a.continueButton {
      margin-right: 19px;
    }
  }
  span.close-icon {
    display: none;
  }
  &.re-opened {
    .close-icon {
      display: inline-block;
    }
    .continueButton {
      user-select: none;
      display: none;
    }
  }
}
